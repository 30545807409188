/**
 * Footer.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import { Layout } from 'antd';

class ContentFooter extends Component {
    render() {
        const {
            Footer
        } = Layout;
        return (
            <Footer style={{ textAlign: 'center' }}>
                Climate Smart © {new Date().getFullYear()} | Created by Logic Plus Information Technologies CC
          </Footer>
        );
    }
}

export default ContentFooter;
