
/**
 * Events.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @author Jonas Tomanga <celleb@logicpp.com.na>
 * @copyright (c) 2019 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import {
    Layout, Breadcrumb, Table, Divider, Button, Popconfirm, message, Modal, Form, Input, Select, Upload, Icon
} from 'antd';
import ContentFooter from '../footer/ContentFooter';
import ContentHeader from '../header/ContentHeader';
import Sidebar from '../menu/Sidebar';
import * as ROUTES from '../../constants/Routes';
import EventsService from '../../services/events.service';
import AuthService from '../../services/auth.service';
import { Redirect } from 'react-router-dom';
import addEllepssisToString from '../../utils/misc.utils';
import { medDateTime, dateSortA } from '../../utils/date.utils';



const CollectionCreateForm = Form.create({ name: 'form_manage_event' })(

    class extends Component {

        render() {
            const {
                visible, onCancel, onCreate, form, confirmLoading, title, okText, selectedEvent, fileList
            } = this.props;
            const { getFieldDecorator } = form;
            const { TextArea } = Input;

            const uploadprops = {
                onRemove: (file) => {
                    this.props.onRemoveFile(file);
                },
                beforeUpload: (file) => {
                    this.props.onAddFile(file);
                    return false;
                },
                fileList,
                accept: 'image/*'
            };

            return (
                <Modal
                    visible={visible}
                    title={title}
                    okText={okText}
                    onCancel={onCancel}
                    onOk={onCreate}
                    confirmLoading={confirmLoading}
                >
                    <Form layout="vertical">
                        <Form.Item label="Type">
                            {getFieldDecorator('eventType', {
                                rules: [{ required: true, message: 'Please select event type' }],
                                initialValue: selectedEvent ? selectedEvent.type : null
                            })(<Select
                                placeholder="Select a Bank"                            >
                                <Select.Option value="event">Event</Select.Option>
                                <Select.Option value="news">News</Select.Option>
                            </Select>)}
                        </Form.Item>
                        <Form.Item label="Title">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please enter event name' }],
                                initialValue: selectedEvent ? selectedEvent.eventName : null

                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item label="Description">
                            {getFieldDecorator('description', {
                                rules: [{ required: true, message: 'Please enter event description' }],
                                initialValue: selectedEvent ? selectedEvent.description : null
                            })(<TextArea autosize={{ minRows: 2, maxRows: 12 }} />
                            )}
                        </Form.Item>
                        <Form.Item label="Image">
                            {getFieldDecorator('eventImg', {
                                rules: [{ required: false, message: 'Please select event theme' }],

                            })(<Upload {...uploadprops}>
                                <Button>
                                    <Icon type="upload" /> Select File
                                </Button>
                            </Upload>)}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);
class Events extends Component {

    eventService = EventsService.create();
    authService = AuthService.create();

    constructor(props) {
        super(props)
        this.state = {
            events: [],
            themes: [],
            showEditForm: false,
            confirmLoading: false,
            okText: '',
            formTitle: '',
            selectedEvent: null,
            isLoggedIn: true,
            fileList: [],
            loading: true
        }
    }

    componentDidMount() {
        if (!this.authService.isUserSignedIn()) {
            message.warn("User session is invalid on has expired. Please sign in.");
            this.setState({ isLoggedIn: false });
            return;
        }
        this.eventService.fetchEvents().then((snapshot) => {
            const events = this.state.events;
            snapshot.forEach(doc => {
                events.push({ id: doc.id, ...doc.data() });
            })
            this.setState({ events });
            this.setState({ loading: false });
        }).catch(() => {
            this.setState({ events: [], loading: false });
            message.error("Something went wrong getting the events.");
        });
    }

    onAddFile = (file) => {
        this.setState(state => ({
            fileList: [...state.fileList, file],
        }));
    }

    onRemoveFile = (file) => {
        this.setState((state) => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    }
    onNewEvent = (event) => {
        this.state.events.push(event);
        this.setState({ events: this.state.events });
    }

    onDelete(id) {
        let deletedEvent = this.state.events.find(item => item.id === id);
        deletedEvent.isDeleted = true;
        delete deletedEvent.id;
        this.eventService.deleteEvent(id, deletedEvent).then(() => {
            const _events = Object.assign(this.state.events, this.state.events.map(item => item.id === id ? { ...deletedEvent } : { ...item }));
            this.setState({ events: _events, selectedEvent: null });
            message.success("Event removed successfully.");
        }).catch((error) => {
            this.setState({ selectedEvent: null });
            message.error("Something went wrong deleting the event");
        });
    }

    onEditModal = (id) => {
        const _selectedEvent = this.state.events.find(item => item.id === id);
        this.setState({ showEditForm: true, okText: "Update", formTitle: "Edit event", selectedEvent: _selectedEvent });
    }
    onAddModal = () => {
        this.setState({ showEditForm: true, okText: "Add", formTitle: "Add a new event" });
    }

    handleCancel = () => {
        this.formRef.props.form.resetFields();
        this.setState({ showEditForm: false, selectedEvent: null });
    }

    handleCreate = () => {
        this.setState({ confirmLoading: true });
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                this.setState({ confirmLoading: false });
                return;
            }
            if (values.eventImg && values.eventImg.fileList.length > 1) {
                this.setState({ confirmLoading: false });
                message.warn("Sorry, there is a limit of one (1) image per event.");
                return;
            }

            let file;
            let isImagePresent = false;

            if (values.eventImg && values.eventImg.fileList.length === 1) {
                file = values.eventImg.file;
                isImagePresent = true;
            }

            const eventType = values.eventType;

            let event = {
                eventName: values.name,
                description: values.description,
                type: eventType,
                isDeleted: false
            };

            if (this.state.selectedEvent) {
                Promise.resolve().then(() => {
                    if (isImagePresent) {
                        return this.eventService.uploadFile(file).then(snapshot => {
                            return snapshot.ref.getDownloadURL();
                        })
                    }
                }).then(downLoadUrl => {
                    if (downLoadUrl) {
                        event['imageUrl'] = downLoadUrl;
                    }
                    event = {
                        ...this.state.selectedEvent,
                        ...event,
                        modifiedBy: sessionStorage.getItem('currentUser'),
                        dateModified: new Date().toISOString()
                    }
                    return this.eventService.updateEvent(this.state.selectedEvent.id, event)
                }).then(() => {
                    const _events = Object.assign(this.state.events, this.state.events
                        .map(item => item.id === this.state.selectedEvent.id ? { id: item.id, ...event } : { ...item }));
                    this.setState({ events: _events, confirmLoading: false, showEditForm: false, selectedEvent: null, fileList: [] });
                    message.success("Event updated successfully.");
                    form.resetFields();
                }).catch((error) => {
                    console.log(error)
                    this.setState({ confirmLoading: false, showEditForm: false, selectedEvent: null, fileList: [] });
                    message.error("Something went wrong updating the event.");
                    form.resetFields();
                });
            } else {

                Promise.resolve().then(() => {
                    if (isImagePresent) {
                        return this.eventService.uploadFile(file).then(snapshot => {
                            return snapshot.ref.getDownloadURL();
                        });
                    }
                }).then(downLoadUrl => {
                    if (downLoadUrl) {
                        event['imageUrl'] = downLoadUrl;
                    }
                    event = {
                        ...event,
                        createdBy: sessionStorage.getItem('currentUser'),
                        entryDate: new Date().toISOString(),
                    }
                    return this.eventService.insertEvent(event);
                }).then(docRef => {
                    this.setState({ confirmLoading: false, showEditForm: false, fileList: [] });
                    this.onNewEvent({ id: docRef.id, ...event });
                    message.success("New event added successfully.");
                    form.resetFields();
                }).catch((error) => {
                    this.setState({ confirmLoading: false, showEditForm: false, fileList: [] });
                    message.error("Something went wrong adding the event.");
                    form.resetFields();
                });
            }
        });
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    render() {
        if (!this.state.isLoggedIn) {
            return <Redirect to={ROUTES.HOME} />
        }
        const { Content } = Layout;
        const data = this.state.events.filter(event => event.isDeleted === false);
        const columns = [
            {
                title: 'Title',
                dataIndex: 'eventName',
                key: 'eventName',
                sorter: (a, b) => a.eventName.localeCompare(b.eventName),
                render: (text, record) => addEllepssisToString(record.eventName),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                sorter: (a, b) => a.description.localeCompare(b.description),
                render: (text, record) => addEllepssisToString(record.description),
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                sorter: (a, b) => a.type.localeCompare(b.type),
            },
            {
                title: 'Date',
                dataIndex: 'entryDate',
                key: 'entryDate',
                sorter: (a, b) => (dateSortA(a.entryDate, b.entryDate)),
                defaultSortOrder: 'descend',
                render: (text) => medDateTime(text),
            },
            {
                title: 'Actions',
                key: 'action',
                align: 'right',
                render: (text, record) => (
                    <span>
                        {record.imageUrl &&
                            <React.Fragment>
                                <Button type="default" icon="download" href={record.imageUrl || '#'} target="_blank"></Button>
                                <Divider type="vertical" />
                            </React.Fragment>
                        }
                        <Button type="primary" icon="edit" onClick={() => this.onEditModal(record.id)}></Button>
                        <Divider type="vertical" />
                        <Popconfirm placement="right" title="Are you sure to delete this event?" onConfirm={() => this.onDelete(record.id)} okText="Yes" cancelText="No">
                            <Button type="danger" icon="delete"></Button>
                        </Popconfirm>

                    </span>
                ),
            }
        ];

        return (

            <Layout className='layout'>
                <ContentHeader />
                <Content className='content'>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>News & Events</Breadcrumb.Item>
                        <Breadcrumb.Item>Manage</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='inner-content'>
                        <Sidebar defaultOpenKeys={[ROUTES.EVENTS]} selectedKeys={["manage-events"]} onAddModal={this.onAddModal} />
                        <Content style={{ padding: '0 24px', minHeight: 280 }}>
                            <Table columns={columns} dataSource={data} rowKey={record => record.id} size="small" loading={this.state.loading} />
                            <CollectionCreateForm
                                wrappedComponentRef={this.saveFormRef}
                                visible={this.state.showEditForm}
                                onCancel={this.handleCancel}
                                onCreate={this.handleCreate}
                                confirmLoading={this.state.confirmLoading}
                                okText={this.state.okText}
                                title={this.state.formTitle}
                                selectedEvent={this.state.selectedEvent}
                                fileList={this.state.fileList}
                                onAddFile={this.onAddFile}
                                onRemoveFile={this.onRemoveFile}
                            />
                        </Content>
                    </Layout>
                </Content>
                <ContentFooter>
                </ContentFooter>
            </Layout>
        );
    }
}

export default Events;
