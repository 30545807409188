/**
 * auth.service.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import firebase from 'firebase/app';


let instance;

export default class AuthService {

    authenticateUser = (email, password) => {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }

    setPersistence = () => {
       return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    }


    resetPwd = (email) => {
        return firebase.auth().sendPasswordResetEmail(email);
    }

    isUserSignedIn = () => {
        if (firebase.auth().currentUser != null || sessionStorage.getItem('currentUser') != null)
            return true;
        return false;
    }

    signOut = () => {
        return firebase.auth().signOut();
    }

    static create() {
        if (!instance) {
            instance = new AuthService();
        }
        return instance;
    }

}