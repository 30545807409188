
/**
 * Publications.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @author Jonas Tomanga <celleb@logicpp.com.na>
 * @copyright (c) 2019 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import {
    Layout, Breadcrumb, Table, Divider, Button, Popconfirm, message, Modal, Form, Input, Select, Upload, Icon
} from 'antd';
import ContentFooter from '../footer/ContentFooter';
import ContentHeader from '../header/ContentHeader';
import Sidebar from '../menu/Sidebar';
import * as ROUTES from '../../constants/Routes';
import PublicationsService from '../../services/publications.service';
import ProjectsService from '../../services/projects.service';
import ThemesService from '../../services/themes.service';
import AuthService from '../../services/auth.service';
import { Redirect } from 'react-router-dom';
//import addEllepssisToString from '../../utils/misc.utils';
import { medDateTime, dateSortA } from '../../utils/date.utils';


const CollectionCreateForm = Form.create({ name: 'form_manage_publication' })(

    class extends Component {

        /**
         * Filters projects, returns all projects in edit mode or 
         * returns un-deleted projects in creation mode
         * @param {Object} selectedPublication 
         * @param {string} selectedPublication.isDeleted
         */
        filterProjects(selectedPublication) {
            return ({ isDeleted }) => (selectedPublication || !isDeleted);
        }

        render() {
            const {
                visible, onCancel, onCreate, form, confirmLoading, title, okText, selectedPublication, projects, fileList
            } = this.props;
            const { getFieldDecorator } = form;
            const { TextArea } = Input;
            const uploadprops = {
                onRemove: (file) => {
                    this.props.onRemoveFile(file);
                },
                beforeUpload: (file) => {
                    this.props.onAddFile(file);
                    return false;
                },
                fileList,
            };

            return (
                <Modal
                    visible={visible}
                    title={title}
                    okText={okText}
                    onCancel={onCancel}
                    onOk={onCreate}
                    confirmLoading={confirmLoading}
                >
                    <Form layout="vertical">
                        <Form.Item label="Publication Title">
                            {getFieldDecorator('title', {
                                rules: [{ required: true, message: 'Please enter publication title' }],
                                initialValue: selectedPublication ? selectedPublication.publicationTitle : null

                            })(
                                <Input allowClear />
                            )}
                        </Form.Item>
                        <Form.Item label="Description (optional)">
                            {getFieldDecorator('description', {
                                rules: [{ required: false, message: 'Please enter a description' }],
                                initialValue: selectedPublication ? selectedPublication.description : null
                            })(
                                <TextArea autosize={{ minRows: 2, maxRows: 12 }} />
                            )}
                        </Form.Item>
                        <Form.Item label="Project">
                            {getFieldDecorator('project', {
                                rules: [{ required: true, message: 'Please select a project.' }],
                                initialValue: selectedPublication ? selectedPublication.project : null
                            })(<Select>
                                {projects.filter(this.filterProjects(selectedPublication)).map(({ id, projectName, isDeleted }) => <Select.Option value={id} key={id}>{projectName} {isDeleted ? '(Deleted)' : ''} </Select.Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label="File">
                            {getFieldDecorator('publicationFile', {
                                rules: [{ required: selectedPublication ? false : true, message: 'Please select a file' }],

                            })(<Upload {...uploadprops}>
                                <Button >
                                    <Icon type="upload" /> Select File
                                </Button>
                            </Upload>)}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);
class Publications extends Component {

    publicationService = PublicationsService.create();
    projectService = ProjectsService.create();
    themeService = ThemesService.create();
    authService = AuthService.create();

    constructor(props) {
        super(props)
        this.state = {
            publications: [],
            themes: [],
            projects: [],
            showEditForm: false,
            confirmLoading: false,
            okText: '',
            formTitle: '',
            selectedPublication: null,
            isLoggedIn: true,
            fileList: [],
            loading: true
        }
    }
    componentDidMount() {
        if (!this.authService.isUserSignedIn()) {
            message.warn("User session is invalid on has expired. Please sign in.");
            this.setState({ isLoggedIn: false });
            return;
        }

        const publications = this.state.publications;
        const projects = this.state.projects;
        const themes = this.state.themes;

        this.publicationService.fetchPublications()
            .then((snapshot) => {
                snapshot.forEach(doc => {
                    publications.push({ id: doc.id, ...doc.data() });
                });
                return this.projectService.fetchProjects();
            })
            .then((snapshot) => {
                snapshot.forEach(doc => {
                    projects.push({ id: doc.id, ...doc.data() });
                });
                return this.themeService.fetchThemes();
            })
            .then((snapshot) => {
                snapshot.forEach(doc => {
                    themes.push({ id: doc.id, ...doc.data() });
                });
                this.setState({ themes, projects, publications, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                this.setState({ publications: [], projects: [], themes: [] });
                message.error(error.message);
            });
    }
    onAddFile = (file) => {
        this.setState(state => ({
            fileList: [...state.fileList, file],
        }));
    }

    getThemeNameById = (id) => {
        const _theme = this.state.themes.find(theme => theme.id === id);
        return _theme.themeName;
    }

    getThemeNameByProject = (project) => {
        const _project = this.getProjectById(project);
        if (!_project || _project === {}) {
            return null;
        }
        const _theme = this.state.themes.find(({ id }) => _project.theme === id) || {};
        return _theme.themeName;
    }

    getProjectNameById = (id) => {
        const _project = this.state.projects.find(theme => theme.id === id);
        return _project.projectName;
    }

    getProjectById = (project) => {
        return this.state.projects.find(({ id }) => id === project) || {};
    }

    onRemoveFile = (file) => {
        this.setState((state) => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    }

    onNewPublication = (publication) => {
        this.state.publications.push(publication);
        this.setState({ publications: this.state.publications });
    }

    onDelete(id) {
        let deletedPublication = this.state.publications.find(item => item.id === id);
        deletedPublication.isDeleted = true;
        delete deletedPublication.id;
        this.publicationService.deletePublication(id, deletedPublication).then(() => {
            const _publications = Object.assign(this.state.publications, this.state.publications.map(item => item.id === id ? { ...deletedPublication } : { ...item }));
            this.setState({ publications: _publications, selectedPublication: null });
            message.success("Publication removed successfully.");
        }).catch((error) => {
            this.setState({ selectedPublication: null });
            message.error("Something went wrong deleting the publication");
        });
    }

    onEditModal = (id) => {
        const _selectedPublication = this.state.publications.find(item => item.id === id);
        this.setState({ showEditForm: true, okText: "Update", formTitle: "Edit publication", selectedPublication: _selectedPublication });
    }
    onAddModal = () => {
        this.setState({ showEditForm: true, okText: "Add", formTitle: "Add a new publication" });
    }

    handleCancel = () => {
        this.formRef.props.form.resetFields();
        this.setState({ showEditForm: false, selectedPublication: null });
    }

    handleCreate = () => {
        this.setState({ confirmLoading: true });
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                this.setState({ confirmLoading: false });
                return;
            }

            if (values.publicationImg && values.publicationImg.fileList.length > 1) {
                this.setState({ confirmLoading: false });
                message.warn("Sorry,  there is a limit of one(1) file per publication.");
                return;
            }

            const file = values.publicationFile ? values.publicationFile.file : null;

            let publication = {
                publicationTitle: values.title,
                description: typeof values.description === 'string' && values.description.trim().length > 0 ? values.description : null,
                project: values.project,
                isDeleted: false
            };

            if (this.state.selectedPublication) {
                publication = {
                    ...this.state.selectedPublication,
                    ...publication,
                    modifiedBy: sessionStorage.getItem('currentUser'),
                    dateModified: new Date().toISOString()
                }
                Promise.resolve().then(() => {
                    if (file) {
                        return this.publicationService.uploadFile(file).then(snapshot => {
                            return snapshot.ref.getDownloadURL();
                        })
                    }
                }).then(downLoadUrl => {
                    if (downLoadUrl) {
                        publication['fileUrl'] = downLoadUrl;
                    }
                    return this.publicationService.updatePublication(this.state.selectedPublication.id, publication)
                }).then(() => {
                    const _publications = Object.assign(this.state.publications, this.state.publications
                        .map(item => item.id === this.state.selectedPublication.id ? { id: item.id, ...publication } : { ...item }));
                    this.setState({ publications: _publications, confirmLoading: false, showEditForm: false, selectedPublication: null, fileList: [] });
                    message.success("Publication updated successfully.");
                    form.resetFields();
                }).catch((error) => {
                    this.setState({ confirmLoading: false, showEditForm: false, selectedPublication: null, fileList: [] });
                    message.error(error.message);
                    form.resetFields();
                });
            }
            else {
                publication = {
                    ...publication,
                    createdBy: sessionStorage.getItem('currentUser'),
                    entryDate: new Date().toISOString(),
                }
                this.publicationService.uploadFile(file)
                    .then(snapshot => {
                        return snapshot.ref.getDownloadURL();
                    }).then(downLoadUrl => {
                        publication['fileUrl'] = downLoadUrl;
                        return this.publicationService.insertPublication(publication);
                    }).then(docRef => {
                        this.setState({ confirmLoading: false, showEditForm: false, fileList: [] });
                        this.onNewPublication({ id: docRef.id, ...publication });
                        message.success("New publication added successfully.");
                        form.resetFields();
                    }).catch((error) => {
                        this.setState({ confirmLoading: false, showEditForm: false, fileList: [] });
                        message.error(error.message);
                        form.resetFields();
                    });
            }


        });
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    addEllipsis = (value) => {
        if (value.length > 20)
            return value.substring(0, 19) + "...";
        return value;
    }

    renderExpanded = record => (<p style={{ margin: 0 }}><strong>Description:</strong> {record.description}</p>)

    render() {
        if (!this.state.isLoggedIn) {
            return <Redirect to={ROUTES.HOME} />
        }
        const { Content } = Layout;
        let data = this.state.publications.filter(publication => publication.isDeleted === false);
        const columns = [
            {
                title: 'Title',
                dataIndex: 'publicationTitle',
                key: 'publicationTitle',
                sorter: (a, b) => a.publicationTitle.localeCompare(b.publicationTitle),
                render: (text) => text,
            },
            {
                title: 'Theme',
                dataIndex: 'project',
                key: 'theme',
                sorter: (a, b) => this.getThemeNameByProject(a.project).localeCompare(this.getThemeNameByProject(b.project)),
                render: (text) => this.getThemeNameByProject(text),
            },
            {
                title: 'Project',
                dataIndex: 'project',
                key: 'project',
                sorter: (a, b) => this.getProjectNameById(a.project).localeCompare(this.getProjectNameById(b.project)),
                render: (text) => this.getProjectNameById(text),
            },
            {
                title: 'Date',
                dataIndex: 'entryDate',
                key: 'entryDate',
                sorter: (a, b) => (dateSortA(a.entryDate, b.entryDate)),
                defaultSortOrder: 'descend',
                render: (text) => medDateTime(text),
            },
            {
                title: 'Actions',
                key: 'action',
                align: 'right',
                render: (text, record) => (
                    <span>
                        <Button type="default" icon="download" href={record.fileUrl || '#'} target="_blank"></Button>
                        <Divider type="vertical" />
                        <Button type="primary" icon="edit" onClick={() => this.onEditModal(record.id)}></Button>
                        <Divider type="vertical" />
                        <Popconfirm placement="right" title="Are you sure to delete this publication?" onConfirm={() => this.onDelete(record.id)} okText="Yes" cancelText="No">
                            <Button type="danger" icon="delete"></Button>
                        </Popconfirm>
                    </span>
                ),
            }
        ];



        return (

            <Layout className='layout'>
                <ContentHeader />
                <Content className='content'>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Publications</Breadcrumb.Item>
                        <Breadcrumb.Item>Manage</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='inner-content'>
                        <Sidebar defaultOpenKeys={[ROUTES.PUBLICATIONS]} selectedKeys={["manage-publications"]} onAddModal={this.onAddModal} />
                        <Content style={{ padding: '0 24px', minHeight: 280 }}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                rowKey={record => record.id} size="small"
                                expandedRowRender={this.renderExpanded}
                                loading={this.state.loading}
                            />
                            <CollectionCreateForm
                                wrappedComponentRef={this.saveFormRef}
                                visible={this.state.showEditForm}
                                onCancel={this.handleCancel}
                                onCreate={this.handleCreate}
                                confirmLoading={this.state.confirmLoading}
                                okText={this.state.okText}
                                title={this.state.formTitle}
                                selectedPublication={this.state.selectedPublication}
                                projects={this.state.projects}
                                fileList={this.state.fileList}
                                onAddFile={this.onAddFile}
                                onRemoveFile={this.onRemoveFile}
                            />
                        </Content>
                    </Layout>
                </Content>
                <ContentFooter />
            </Layout>
        );
    }
}

export default Publications;
