/**
 * Home.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import { Card } from 'antd';
import LoginForm from '../auth/LoginForm'
import Footer from '../footer/Footer'

class Home extends Component {
    render() {
        return (
            <div className='main-container'>
                    <div className='login-card-container'>
                        <img src={require('../../images/web_login_logo.png')} alt='logo' />
                    </div>
                    <div className='login-card-container'>
                        <Card
                            title="Climate Smart Portal - Login"
                            className="login-card">
                            <LoginForm />
                        </Card>
                    </div>
                <Footer></Footer>
            </div>

        );
    }
}


export default Home;
