/**
 * Footer.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import { Layout } from 'antd';

class Footer extends Component {
    render() {
        const {
            Header
        } = Layout;
        return (
            <div>
                <Header className="site-footer" style={{ textAlign: 'center', color: 'white' }}>Climate Smart © {new Date().getFullYear()} | Created by Logic Plus Information Technologies CC</Header>
            </div>
        );
    }
}

export default Footer;
