
/**
 * Themes.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @author Jonas Tomanga <celleb@logicpp.com.na>
 * @copyright (c) 2019 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import {
    Layout, Breadcrumb, Table, Divider, Button, Popconfirm, message, Modal, Form, Input
} from 'antd';
import ContentFooter from '../footer/ContentFooter';
import ContentHeader from '../header/ContentHeader';
import Sidebar from '../menu/Sidebar';
import * as ROUTES from '../../constants/Routes';
import ThemesService from '../../services/themes.service';
import AuthService from '../../services/auth.service';
import { Redirect } from 'react-router-dom';
//import addEllepssisToString from '../../utils/misc.utils';
import { medDateTime, dateSortA } from '../../utils/date.utils';



const CollectionCreateForm = Form.create({ name: 'form_manage_theme' })(

    class extends Component {
        render() {
            const {
                visible, onCancel, onCreate, form, confirmLoading, title, okText, selectedTheme
            } = this.props;
            const { getFieldDecorator } = form;
            const { TextArea } = Input;

            return (
                <Modal
                    visible={visible}
                    title={title}
                    okText={okText}
                    onCancel={onCancel}
                    onOk={onCreate}
                    confirmLoading={confirmLoading}
                >
                    <Form layout="vertical">
                        <Form.Item label="Theme Name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please enter theme name' }],
                                initialValue: selectedTheme ? selectedTheme.themeName : null

                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item label="Description">
                            {getFieldDecorator('description', {
                                rules: [{ required: true, message: 'Please enter theme description' }],
                                initialValue: selectedTheme ? selectedTheme.description : null
                            })(<TextArea autosize={{ minRows: 2, maxRows: 6 }} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);
class Themes extends Component {

    themeService = ThemesService.create();
    authService = AuthService.create();

    constructor(props) {
        super(props)
        this.state = {
            themes: [],
            showEditForm: false,
            confirmLoading: false,
            okText: '',
            formTitle: '',
            selectedTheme: null,
            isLoggedIn: true,
            loading: true
        }
    }
    componentDidMount() {
        if (!this.authService.isUserSignedIn()) {
            message.warn("User session is invalid on has expired. Please sign in.");
            this.setState({ isLoggedIn: false });
            return;
        }
        this.themeService.fetchThemes().then((snapshot) => {
            snapshot.forEach(doc => {
                this.state.themes.push({ id: doc.id, ...doc.data() });
                this.setState({ themes: this.state.themes });
                this.setState({ loading: false });
            })
        }).catch((error) => {
            this.setState({ loading: false });
            this.setState({ themes: [] });
            message.error(error.message);
        });

    }

    onNewTheme = (theme) => {
        this.state.themes.push(theme);
        this.setState({ themes: this.state.themes });
    }

    onDelete(id) {
        let deletedTheme = this.state.themes.find(item => item.id === id);
        deletedTheme.isDeleted = true;
        delete deletedTheme.id;
        this.themeService.deleteTheme(id, deletedTheme).then(() => {
            const _themes = Object.assign(this.state.themes, this.state.themes
                .map(item => item.id === id ? { ...deletedTheme } : { ...item }));
            this.setState({ themes: _themes });
            message.success("Theme removed successfully.");
        }).catch((error) => {
            message.error(error.message);
        });
    }

    onEditModal = (id) => {
        const selectedTheme = this.state.themes.find(item => item.id === id);
        this.setState({ showEditForm: true, okText: "Update", formTitle: "Edit theme", selectedTheme: selectedTheme });
    }
    onAddModal = () => {
        this.setState({ showEditForm: true, okText: "Add", formTitle: "Add a new theme" });
    }

    handleCancel = () => {
        this.formRef.props.form.resetFields();
        this.setState({ showEditForm: false, selectedTheme: null });
    }

    handleCreate = () => {
        this.setState({ confirmLoading: true });
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                this.setState({ confirmLoading: false });
                return;
            }
            let theme = {
                themeName: values.name,
                description: values.description,
                isDeleted: false
            };

            if (this.state.selectedTheme) {
                theme = {
                    ...this.state.selectedTheme,
                    ...theme,
                    modifiedBy: sessionStorage.getItem('currentUser'),
                    dateModified: new Date().toISOString()
                }
                this.themeService.updateTheme(this.state.selectedTheme.id, theme)
                    .then(() => {
                        const _themes = Object.assign(this.state.themes, this.state.themes
                            .map(item => item.id === this.state.selectedTheme.id ? { id: item.id, ...theme } : { ...item }));
                        this.setState({ themes: _themes, confirmLoading: false, showEditForm: false, selectedTheme: null });
                        message.success("Theme updated successfully.");
                        form.resetFields();

                    })
                    .catch((error) => {
                        this.setState({ confirmLoading: false, showEditForm: false, selectedTheme: null });
                        message.error(error.message);
                        form.resetFields();

                    });
            }
            else {
                theme = {
                    ...theme,
                    entryDate: new Date().toISOString(),
                    createdBy: sessionStorage.getItem('currentUser'),
                }
                this.themeService.insertTheme(theme)
                    .then((docRef) => {
                        this.setState({ confirmLoading: false, showEditForm: false });
                        this.onNewTheme({ id: docRef.id, ...theme });
                        message.success("New theme added successfully.");
                        form.resetFields();
                    }).catch((error) => {
                        this.setState({ confirmLoading: false, showEditForm: false });
                        message.error(error.message);
                        form.resetFields();

                    });
            }
        });
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    render() {
        if (!this.state.isLoggedIn) {
            return <Redirect to={ROUTES.HOME} />
        }
        const { Content } = Layout;
        const data = this.state.themes.filter(theme => theme.isDeleted === false);
        const columns = [
            {
                title: 'Theme Name',
                dataIndex: 'themeName',
                key: 'themeName',
                sorter: (a, b) => a.description.localeCompare(b.description),
                render: (text) => text,
            },
            {
                title: 'Theme Description',
                dataIndex: 'description',
                key: 'description',
                sorter: (a, b) => a.description.localeCompare(b.description),
                render: (text) => text,
            },
            {
                title: 'Date',
                dataIndex: 'entryDate',
                key: 'entryDate',
                sorter: (a, b) => (dateSortA(a.entryDate, b.entryDate)),
                defaultSortOrder: 'descend',
                render: (text) => medDateTime(text),
            },
            {
                title: 'Actions',
                key: 'action',
                align: 'right',
                render: (text, record) => (
                    <span>
                        <Button type="primary" icon="edit" onClick={() => this.onEditModal(record.id)}></Button>
                        <Divider type="vertical" />
                        <Popconfirm placement="right" title="Are you sure to delete this theme?" onConfirm={() => this.onDelete(record.id)} okText="Yes" cancelText="No">
                            <Button type="danger" icon="delete"></Button>
                        </Popconfirm>

                    </span>
                ),
            }
        ];

        return (

            <Layout className='layout'>
                <ContentHeader />
                <Content className='content'>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Themes</Breadcrumb.Item>
                        <Breadcrumb.Item>Manage</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='inner-content'>
                        <Sidebar defaultOpenKeys={[ROUTES.THEMES]} selectedKeys={["manage-themes"]} onAddModal={this.onAddModal} />
                        <Content style={{ padding: '0 24px', minHeight: 280 }}>
                            <Table columns={columns} dataSource={data} rowKey={record => record.id} size="small" loading={this.state.loading} />
                            <CollectionCreateForm
                                wrappedComponentRef={this.saveFormRef}
                                visible={this.state.showEditForm}
                                onCancel={this.handleCancel}
                                onCreate={this.handleCreate}
                                confirmLoading={this.state.confirmLoading}
                                okText={this.state.okText}
                                title={this.state.formTitle}
                                selectedTheme={this.state.selectedTheme}
                            />
                        </Content>
                    </Layout>
                </Content>
                <ContentFooter>
                </ContentFooter>
            </Layout>
        );
    }
}

export default Themes;
