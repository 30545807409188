

/**
 * teams.service.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import firebase from 'firebase/app';
import 'firebase/firestore'

let instance;

export default class TeamsService {

    db = firebase.firestore();
    collection = "teams";
    
    fetchTeams = () => {
        return this.db.collection(this.collection).get();
    }

    insertTeam = (team) => {
        return this.db.collection(this.collection).add({...team});
    }

    updateTeam = (id, team) =>{
        return this.db.collection(this.collection).doc(id).set({...team});
    }

    deleteTeam = (id, team) =>{
        console.log(team);
        return this.db.collection(this.collection).doc(id).set({...team});
    }

    static create() {
        if (!instance) {
            instance = new TeamsService();
        }
        return instance;
    }

}