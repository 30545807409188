/**
 * date.util.js
 *
 * @author Jonas Tomanga <celleb@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import { DateTime } from "luxon";

/**
 * Returns a string indicating the duration from the current date 
 * in either seconds, minutes, hours, days, weeks, months or years
 * i.e `3 seconds ago`
 * @param {string} date 
 */

function value(value, unit) {
    unit = Math.abs(value) > 1 ? `${unit}s` : unit;
    const tense = (value >= 0) ? 'ago' : 'to go';
    if (unit === 'sec' && value < Math.abs(30)) {
        return 'now';
    }
    return `${Math.abs(value)} ${unit} ${tense}`;
}
export function duration(date) {
    const start = DateTime.local();
    const end = parseDate(date)
    const duration = start.diff(end, ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years']).toObject();

    if (duration.years !== 0) {
        return value(duration.years, 'year');
    } else if (duration.months !== 0) {
        return value(duration.months, 'month');
    } else if (duration.weeks !== 0) {
        return value(duration.weeks, 'week');
    } else if (duration.days !== 0) {
        return value(duration.days, 'day');
    } else if (duration.hours !== 0) {
        return value(duration.hours, 'hour');
    } else if (duration.minutes !== 0) {
        return value(duration.minutes, 'minute');
    } else {
        return value(parseInt(duration.seconds), 'sec');
    }
}

/**
 * Formats the JavaScript date object into a 
 * Full Date string using locale
 * @param {Date} date - JavaScript Date Object
 */
export function dateFormat(date) {
    return parseDate(date).toLocaleString(DateTime.DATE_FULL);
}

/**
 * Formats the JavaScript date object into a 24 hour Time string using locale
 * @param {Date|String} date - JavaScript Date Object or ISO date string
 */
export function time24Format(date) {
    return parseDate(date).toLocaleString(DateTime.TIME_24_SIMPLE);
}


/**
 * Formats the JavaScript date object into a DATETIME object using locale
 * @param {Date} date - JavaScript Date Object
 */
export function medDateTime(date) {
    return parseDate(date).toLocaleString(DateTime.DATETIME_MED);
}

/**
 * Parses date into a luxon DateTime object
 * @param {(string|Date)} date 
 */
function parseDate(date) {
    return typeof date === 'object' ? DateTime.fromJSDate(date) : DateTime.fromISO(date);
}

/**
 * Sort date in descending order
 * @param {*} a 
 * @param {*} b 
 */
export function dateSortD(a, b) {
    return (new Date(b)) - (new Date(a));
}

/**
 * Sort date in ascending order
 * @param {*} a 
 * @param {*} b 
 */
export function dateSortA(a, b) {
    return (new Date(a)) - (new Date(b));
}