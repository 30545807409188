

/**
 * themes.service.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import firebase from 'firebase/app';
import 'firebase/firestore'

let instance;

export default class ThemesService {

    db = firebase.firestore();
    collection = "themes";
    
    fetchThemes = () => {
        return this.db.collection(this.collection).get();
    }

    insertTheme = (theme) => {
        return this.db.collection(this.collection).add({...theme});
    }

    updateTheme = (id, theme) =>{
        return this.db.collection(this.collection).doc(id).set({...theme});
    }

    deleteTheme = (id, theme) =>{
        return this.db.collection(this.collection).doc(id).set({...theme});
    }

    static create() {
        if (!instance) {
            instance = new ThemesService();
        }
        return instance;
    }

}