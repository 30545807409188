/**
 * config.prod.js
 *
 * @author Jonas Tomanga <celleb@logicpp.com.na>
 * @copyright (c) 2019 Logic Plus Information Technologies CC
 * All rights reserved
 */

export const config = {
    apiKey: "AIzaSyCzsaMn2EmvOya6SWEpdam5nAbcsNznb1o",
    authDomain: "assar-dc822.firebaseapp.com",
    databaseURL: "https://assar-dc822.firebaseio.com",
    projectId: "assar-dc822",
    storageBucket: "assar-dc822.appspot.com",
    messagingSenderId: "320324421410"
};