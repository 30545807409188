/**
 * users.service.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import firebase from 'firebase/app';
import 'firebase/firestore'

let instance;

export default class UsersService {

    db = firebase.firestore();
    collection = "users";

    fetchUsers = () => {
        return this.db.collection(this.collection).get();
    }

    createUser = (email, password) => {
        return firebase.auth().createUserWithEmailAndPassword(email, password);
    }

    insertUser = (user) => {
        return this.db.collection(this.collection).add({
            ...user
        });
    }
    
    updateUser = (id, user) => {
        return this.db.collection(this.collection).doc(id).set({
            ...user
        });
    }

    deleteUser = (id, user) => {
        return this.db.collection(this.collection).doc(id).set({
            ...user
        });
    }

    static create() {
        if (!instance) {
            instance = new UsersService();
        }
        return instance;
    }

}