/**
 * App.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */


import React, { Component } from 'react';
import { BrowserRouter,Route,Switch } from 'react-router-dom';
import Home  from './components/landing/Home';
import Reset  from  './components/auth/Reset';
import Events  from  './components/events/Events';
import Teams  from  './components/teams/Teams';
import Themes  from  './components/themes/Themes';
import Projects  from  './components/projects/Projects';
import Users  from  './components/users/Users';
import Publications  from  './components/publications/Publications';
import {init as firebaseInit} from './config/Firebase'
import './App.css';
import * as ROUTES from './constants/Routes';

class App extends Component {
  constructor(props) {
    super(props)
    firebaseInit()
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={ROUTES.HOME} component={Home} />
          <Route path={ROUTES.PUBLICATIONS} component={Publications} />
          <Route path={ROUTES.RESET_PWD} component={Reset} />
          <Route path={ROUTES.EVENTS} component={Events} />
          <Route path={ROUTES.TEAMS} component={Teams} />
          <Route path={ROUTES.THEMES} component={Themes} />
          <Route path={ROUTES.PROJECTS} component={Projects} />
          <Route path={ROUTES.USERS} component={Users} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
