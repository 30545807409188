/**
 * Reset.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import { Card } from 'antd';
import ResetForm from './ResetForm'
import Footer from '../footer/Footer'


class Reset extends Component {
    render() {
        return (
            <div className='main-container'>
                <div className='login-card-container'>
                    <img src={require('../../images/web_login_logo.png')} alt='logo' />
                </div>
                <div className='login-card-container'>
                    <Card
                        title="Climate Change Portal - Login"
                        className="login-card">
                        <p>Please enter your email and we will send you a link to reset your password.</p>
                        <ResetForm />
                    </Card>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default Reset;