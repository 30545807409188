/**
 * projects.service.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import firebase from 'firebase/app';
import 'firebase/firestore'

let instance;

export default class ProjectsService {

    db = firebase.firestore();
    collection = "projects";

    fetchProjects = () => {
        return this.db.collection(this.collection).get();
    }

    insertProject = (poject) => {
        return this.db.collection(this.collection).add({
            ...poject
        });
    }

    updateProject = (id, poject) => {
        return this.db.collection(this.collection).doc(id).set({
            ...poject
        });
    }

    deleteProject = (id, poject) => {
        return this.db.collection(this.collection).doc(id).set({
            ...poject
        });
    }

    static create() {
        if (!instance) {
            instance = new ProjectsService();
        }
        return instance;
    }

}