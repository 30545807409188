
/**
 * Projects.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @author Jonas Tomanga <celleb@logicpp.com.na>
 * @copyright (c) 2019 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import {
    Layout, Breadcrumb, Table, Divider, Button, Popconfirm, message, Modal, Form, Input, Select
} from 'antd';
import ContentFooter from '../footer/ContentFooter';
import ContentHeader from '../header/ContentHeader';
import Sidebar from '../menu/Sidebar';
import * as ROUTES from '../../constants/Routes';
import ThemesService from '../../services/themes.service';
import ProjectsService from '../../services/projects.service';
import AuthService from '../../services/auth.service';
import { Redirect } from 'react-router-dom';
import addEllepssisToString from '../../utils/misc.utils';
import { medDateTime, dateSortA } from '../../utils/date.utils';


const CollectionCreateForm = Form.create({ name: 'form_manage_project' })(

    class extends Component {
        /**
         * Filters themes, returns all themes in edit mode or 
         * returns un-deleted themes in creation mode
         * @param {Object} selectedProject
         * @param {string} selectedProject.isDeleted
         */
        filterThemes(selectedProject) {
            return ({ isDeleted }) => (selectedProject || !isDeleted);
        }
        render() {
            const {
                visible, onCancel, onCreate, form, confirmLoading, title, okText, selectedProject, themes
            } = this.props;
            const { getFieldDecorator } = form;
            const { TextArea } = Input;
            return (
                <Modal
                    visible={visible}
                    title={title}
                    okText={okText}
                    onCancel={onCancel}
                    onOk={onCreate}
                    confirmLoading={confirmLoading}
                >
                    <Form layout="vertical">
                        <Form.Item label="Project Theme">
                            {getFieldDecorator('theme', {
                                rules: [{ required: true, message: 'Please select project theme' }],
                                initialValue: selectedProject ? selectedProject.theme : null
                            })(<Select  >
                                {themes.filter(this.filterThemes(selectedProject)).map(({ id, themeName, isDeleted }) => <Select.Option value={id} key={id}>{themeName} {isDeleted ? '(Deleted)' : ''}</Select.Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label="Project Name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please enter project name' }],
                                initialValue: selectedProject ? selectedProject.projectName : null
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item label="YouTube Channel">
                            {getFieldDecorator('youtube', {
                                rules: [{ required: false, message: 'Please enter project description' }],
                                initialValue: selectedProject ? selectedProject.youtube : null
                            })(<Input />
                            )}
                        </Form.Item>
                        <Form.Item label="Description">
                            {getFieldDecorator('description', {
                                rules: [{ required: true, message: 'Please enter project description' }],
                                initialValue: selectedProject ? selectedProject.description : null
                            })(<TextArea autosize={{ minRows: 2, maxRows: 6 }} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);
class Projects extends Component {

    projectService = ProjectsService.create();
    themeService = ThemesService.create();
    authService = AuthService.create();

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            themes: [],
            showEditForm: false,
            confirmLoading: false,
            okText: '',
            formTitle: '',
            selectedProject: null,
            isLoggedIn: true,
            loading: true,
            updates: []
        }
    }
    componentDidMount() {
        if (!this.authService.isUserSignedIn()) {
            message.warn("User session is invalid on has expired. Please sign in.");
            this.setState({ isLoggedIn: false });
            return;
        }
        this.themeService.fetchThemes()
            .then((snapshot) => {
                const themes = this.state.themes;
                snapshot.forEach(doc => {
                    themes.push({ id: doc.id, ...doc.data() });
                })
                this.setState({ themes })
                return this.projectService.fetchProjects();
            }).then((snapshot) => {
                const projects = this.state.projects;
                snapshot.forEach(doc => {
                    projects.push({ id: doc.id, ...doc.data() });
                });
                this.setState({ projects, loading: false });
            }).catch((error) => {
                this.setState({ loading: false });
                message.error(error.message);
            });

    }

    onNewProject = (project) => {
        this.state.projects.push(project);
        this.setState({ projects: this.state.projects });
    }

    getThemeNameById = (theme) => {
        const _theme = this.state.themes.find(({ id }) => theme === id) || {};
        return _theme.themeName;
    }

    onDelete(id) {
        let deletedProject = this.state.projects.find(item => item.id === id);
        deletedProject.isDeleted = true;
        delete deletedProject.id;
        this.projectService.deleteProject(id, deletedProject).then(() => {
            const _projects = Object.assign(this.state.projects, this.state.projects
                .map(item => item.id === id ? { id: item.id, ...deletedProject } : { ...item }));
            this.setState({ projects: _projects, selectedProject: null });
            message.success("Project removed successfully.");
        }).catch((error) => {
            this.setState({ selectedProject: null });
            message.error(error.message);
        });
    }

    onEditModal = (id) => {
        const selectedProject = this.state.projects.find(item => item.id === id);
        this.setState({ showEditForm: true, okText: "Update", formTitle: "Edit project", selectedProject: selectedProject });
    }
    onAddModal = () => {
        this.setState({ showEditForm: true, okText: "Add", formTitle: "Add a new project" });
    }

    handleCancel = () => {
        this.formRef.props.form.resetFields();
        this.setState({ selectedProject: null, showEditForm: false });

    }

    handleCreate = () => {
        this.setState({ confirmLoading: true });
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                this.setState({ confirmLoading: false });
                return;
            }

            const project = {
                projectName: values.name,
                youtube: typeof values.youtube === 'string' && values.youtube.trim().length > 0 ? values.youtube : null,
                description: values.description,
                theme: values.theme,
                isDeleted: false
            };
            console.log(project);
            if (this.state.selectedProject) {
                const data = { ...this.state.selectedProject, ...project, modifiedBy: sessionStorage.getItem('currentUser'), dateModified: new Date().toISOString() };
                this.projectService.updateProject(this.state.selectedProject.id, data)
                    .then(() => {
                        const _projects = Object.assign(this.state.projects, this.state.projects
                            .map(item => item.id === this.state.selectedProject.id ? { id: item.id, ...data } : { ...item }));
                        this.setState({ projects: _projects, confirmLoading: false, showEditForm: false, selectedProject: null });
                        message.success("Project updated successfully.");
                        form.resetFields();
                    })
                    .catch((error) => {
                        this.setState({ confirmLoading: false, showEditForm: false, selectedProject: null });
                        message.error(error.message);
                        form.resetFields();
                    });
            }
            else {
                const data = { ...project, entryDate: new Date().toISOString(), createdBy: sessionStorage.getItem('currentUser') };
                this.projectService.insertProject(data)
                    .then((docRef) => {
                        this.setState({ confirmLoading: false, showEditForm: false });
                        this.onNewProject({ id: docRef.id, ...data });
                        message.success("New project added successfully.");
                        form.resetFields();
                    }).catch((error) => {
                        this.setState({ confirmLoading: false, showEditForm: false });
                        message.error(error.message);
                        form.resetFields();
                    });
            }

        });

    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    render() {
        if (!this.state.isLoggedIn) {
            return <Redirect to={ROUTES.HOME} />
        }
        const { Content } = Layout;
        const data = this.state.projects.filter(project => project.isDeleted === false);
        const columns = [
            {
                title: 'Project Name',
                dataIndex: 'projectName',
                key: 'projectName',
                sorter: (a, b) => a.projectName.localeCompare(b.projectName),
                render: (text) => addEllepssisToString(text),
            },
            {
                title: 'Project Description',
                dataIndex: 'description',
                key: 'description',
                sorter: (a, b) => a.description.localeCompare(b.description),
                render: (text) => addEllepssisToString(text),
            },
            {
                title: 'Project Theme',
                dataIndex: 'theme',
                key: 'theme',
                sorter: (a, b) => this.getThemeNameById(a.theme).localeCompare(this.getThemeNameById(b.theme)),
                render: (text) => addEllepssisToString(this.getThemeNameById(text)),
            }, {
                title: 'YouTube Channel',
                dataIndex: 'youtube',
                key: 'youtube',
                render: (text, ) => text ? addEllepssisToString(text) : null,
            },
            {
                title: 'Date',
                dataIndex: 'entryDate',
                key: 'entryDate',
                sorter: (a, b) => (dateSortA(a.entryDate, b.entryDate)),
                defaultSortOrder: 'descend',
                render: (text) => medDateTime(text),
            },
            {
                title: 'Actions',
                key: 'action',
                align: 'right',
                render: (text, record) => (
                    <span>
                        <Button type="primary" icon="edit" onClick={() => this.onEditModal(record.id)}></Button>
                        <Divider type="vertical" />
                        <Popconfirm placement="right" title="Are you sure to delete this project?" onConfirm={() => this.onDelete(record.id)} okText="Yes" cancelText="No">
                            <Button type="danger" icon="delete"></Button>
                        </Popconfirm>

                    </span>
                ),
            }
        ];

        return (

            <Layout className='layout'>
                <ContentHeader />
                <Content className='content'>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Projects</Breadcrumb.Item>
                        <Breadcrumb.Item>Manage</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='inner-content'>
                        <Sidebar defaultOpenKeys={[ROUTES.PROJECTS]} selectedKeys={["manage-projects"]} onAddModal={this.onAddModal} />
                        <Content style={{ padding: '0 24px', minHeight: 280 }}>
                            <Table columns={columns} dataSource={data} rowKey={record => record.id} size="small" loading={this.state.loading} />
                            <CollectionCreateForm
                                wrappedComponentRef={this.saveFormRef}
                                visible={this.state.showEditForm}
                                onCancel={this.handleCancel}
                                onCreate={this.handleCreate}
                                confirmLoading={this.state.confirmLoading}
                                okText={this.state.okText}
                                title={this.state.formTitle}
                                selectedProject={this.state.selectedProject}
                                themes={this.state.themes}
                            />
                        </Content>
                    </Layout>
                </Content>
                <ContentFooter>
                </ContentFooter>
            </Layout>
        );
    }
}

export default Projects;
