/**
 * Header.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { Layout, Row, Col, Button, message } from 'antd';
import AuthService from '../../services/auth.service'
import * as ROUTES from '../../constants/Routes';

class ContentHeader extends Component {
    authService = AuthService.create();

    state = {
        isLoggedIn: true
    }

    signOut = () => {
        this.authService.signOut().then(() => {
            this.setState({ isLoggedIn: false });
        }).catch((error) => {
            message.error(error.message);
        })
    }

    render() {
        if (!this.state.isLoggedIn) {
            return <Redirect to={ROUTES.HOME} />
        }
        const { Header } = Layout;
        return (
            <Header className="header">
                <Row>
                    <Col span={8}>
                        <div className='logo'>
                            <img src={require('../../images/web_login_logo.png')} alt="Logo" />
                            climate <strong>smart</strong> portal
                        </div>
                    </Col>
                    <Col span={4} offset={12}>
                        <div style={{ float: 'right' }}>
                            <Button type="primary" icon="logout" onClick={() => this.signOut()} >Logout</Button>
                        </div>
                    </Col>
                </Row>
            </Header>
        );
    }
}

export default ContentHeader;
