
/**
 * Teams.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @author Jonas Tomanga <celleb@logicpp.com.na>
 * @copyright (c) 2019 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import {
    Layout, Breadcrumb, Table, Divider, Button, Popconfirm, message, Modal, Form, Input
} from 'antd';
import ContentFooter from '../footer/ContentFooter';
import ContentHeader from '../header/ContentHeader';
import Sidebar from '../menu/Sidebar';
import * as ROUTES from '../../constants/Routes';
import TeamsService from '../../services/teams.service';
import AuthService from '../../services/auth.service';
import { Redirect } from 'react-router-dom';
import { medDateTime, dateSortA } from '../../utils/date.utils';



const CollectionCreateForm = Form.create({ name: 'form_manage_team' })(

    class extends Component {
        render() {
            const {
                visible, onCancel, onCreate, form, confirmLoading, title, okText, selectedTeam
            } = this.props;
            const { getFieldDecorator } = form;
            const { TextArea } = Input;

            return (
                <Modal
                    visible={visible}
                    title={title}
                    okText={okText}
                    onCancel={onCancel}
                    onOk={onCreate}
                    confirmLoading={confirmLoading}
                >
                    <Form layout="vertical">
                        <Form.Item label="Team Name">
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please enter team name' }],
                                initialValue: selectedTeam ? selectedTeam.teamName : null

                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item label="Description">
                            {getFieldDecorator('description', {
                                rules: [{ required: true, message: 'Please enter team description' }],
                                initialValue: selectedTeam ? selectedTeam.description : null
                            })(<TextArea autosize={{ minRows: 2, maxRows: 6 }} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);
class Teams extends Component {

    teamService = TeamsService.create();
    authService = AuthService.create();

    constructor(props) {
        super(props)
        this.state = {
            teams: [],
            showEditForm: false,
            confirmLoading: false,
            okText: '',
            formTitle: '',
            selectedTeam: null,
            isLoggedIn: true,
            loading: true
        }
    }
    componentDidMount() {
        if (!this.authService.isUserSignedIn()) {
            message.warn("User session is invalid on has expired. Please sign in.");
            this.setState({ isLoggedIn: false });
            return;
        }
        this.teamService.fetchTeams().then((snapshot) => {
            const teams = this.state.teams;
            snapshot.forEach(doc => {
                teams.push({ id: doc.id, ...doc.data() });
            });
            this.setState({ teams: teams, loading: false });
        }).catch((error) => {
            this.setState({ loading: false });
            message.error(error.message);
        });

    }

    onNewTeam = (team) => {
        this.state.teams.push(team);
        this.setState({ teams: this.state.teams });
    }

    onDelete(id) {
        let deletedTeam = this.state.teams.find(item => item.id === id);
        deletedTeam.isDeleted = true;
        delete deletedTeam.id;
        this.teamService.deleteTeam(id, deletedTeam).then(() => {
            const _teams = Object.assign(this.state.teams, this.state.teams
                .map(item => item.id === id ? { ...deletedTeam } : { ...item }));
            this.setState({ teams: _teams });
            message.success("Team removed successfully.");
        }).catch((error) => {
            message.error(error.message);
        });
    }

    onEditModal = (id) => {
        const selectedTeam = this.state.teams.find(item => item.id === id);
        this.setState({ showEditForm: true, okText: "Update", formTitle: "Edit team", selectedTeam: selectedTeam });
    }
    onAddModal = () => {
        this.setState({ showEditForm: true, okText: "Add", formTitle: "Add a new team" });
    }

    handleCancel = () => {
        this.formRef.props.form.resetFields();
        this.setState({ showEditForm: false, selectedTeam: null });
    }

    handleCreate = () => {
        this.setState({ confirmLoading: true });
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                this.setState({ confirmLoading: false });
                return;
            }
            let team = {
                ...this.state.selectedTeam,
                teamName: values.name,
                description: values.description,
                isDeleted: false
            };
            if (this.state.selectedTeam) {
                team = {
                    ...team,
                    modifiedBy: sessionStorage.getItem('currentUser'),
                    dateModified: new Date().toISOString()
                }
                this.teamService.updateTeam(this.state.selectedTeam.id, team)
                    .then(() => {
                        const _teams = this.state.teams.map(item => {
                            if (item.id === this.state.selectedTeam.id) {
                                return { ...item, ...team };
                            } else {
                                return item;
                            }
                        })
                        console.log(team, this.state.selectedTeam.id, values, _teams);
                        this.setState({ teams: _teams, confirmLoading: false, showEditForm: false, selectedTeam: null });
                        message.success("Team updated successfully.");
                        form.resetFields();
                    })
                    .catch((error) => {
                        this.setState({ confirmLoading: false, showEditForm: false, selectedTeam: null });
                        message.error(error.message);
                        form.resetFields();
                    });
            }
            else {
                team = {
                    ...team,
                    entryDate: new Date().toISOString(),
                    createdBy: sessionStorage.getItem('currentUser')
                }
                this.teamService.insertTeam(team)
                    .then((docRef) => {
                        this.setState({ confirmLoading: false, showEditForm: false });
                        this.onNewTeam({ id: docRef.id, ...team });
                        message.success("New team added successfully.");
                        form.resetFields();
                    }).catch((error) => {
                        this.setState({ confirmLoading: false, showEditForm: false });
                        message.error(error.message);
                        form.resetFields();
                    });
            }
        });
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    render() {
        if (!this.state.isLoggedIn) {
            return <Redirect to={ROUTES.HOME} />
        }
        const { Content } = Layout;
        const data = this.state.teams.filter(team => team.isDeleted === false);
        const columns = [
            {
                title: 'Team Name',
                dataIndex: 'teamName',
                key: 'teamName',
                sorter: (a, b) => a.description.localeCompare(b.teamName),
                render: (text, record) => text,
            },
            {
                title: 'Team Description',
                dataIndex: 'description',
                key: 'description',
                sorter: (a, b) => a.description.localeCompare(b.description),
                render: (text, record) => text,
            },
            {
                title: 'Date',
                dataIndex: 'entryDate',
                key: 'entryDate',
                sorter: (a, b) => (dateSortA(a.entryDate, b.entryDate)),
                defaultSortOrder: 'descend',
                render: (text) => medDateTime(text),
            },
            {
                title: 'Actions',
                key: 'action',
                align: 'right',
                render: (text, record) => (
                    <span>
                        <Button type="primary" icon="edit" onClick={() => this.onEditModal(record.id)}></Button>
                        <Divider type="vertical" />
                        <Popconfirm placement="right" title="Are you sure to delete this team?" onConfirm={() => this.onDelete(record.id)} okText="Yes" cancelText="No">
                            <Button type="danger" icon="delete"></Button>
                        </Popconfirm>

                    </span>
                ),
            }
        ];

        return (

            <Layout className='layout'>
                <ContentHeader />
                <Content className='content'>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Teams</Breadcrumb.Item>
                        <Breadcrumb.Item>Manage</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='inner-content'>
                        <Sidebar defaultOpenKeys={[ROUTES.TEAMS]} selectedKeys={["manage-teams"]} onAddModal={this.onAddModal} />
                        <Content style={{ padding: '0 24px', minHeight: 280 }}>
                            <Table columns={columns} dataSource={data} rowKey={record => record.id} size="small" loading={this.state.loading} />
                            <CollectionCreateForm
                                wrappedComponentRef={this.saveFormRef}
                                visible={this.state.showEditForm}
                                onCancel={this.handleCancel}
                                onCreate={this.handleCreate}
                                confirmLoading={this.state.confirmLoading}
                                okText={this.state.okText}
                                title={this.state.formTitle}
                                selectedTeam={this.state.selectedTeam}
                            />
                        </Content>
                    </Layout>
                </Content>
                <ContentFooter>
                </ContentFooter>
            </Layout>
        );
    }
}

export default Teams;
