
/**
 * Users.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @author Jonas Tomanga <celleb@logicpp.com.na>
 * @copyright (c) 2019 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import {
    Layout, Breadcrumb, Table, Divider, Button, Popconfirm, message, Modal, Form, Input, Select
} from 'antd';
import ContentFooter from '../footer/ContentFooter';
import ContentHeader from '../header/ContentHeader';
import Sidebar from '../menu/Sidebar';
import * as ROUTES from '../../constants/Routes';
import firebase from 'firebase/app';
import TeamService from '../../services/teams.service';
import UsersService from '../../services/users.service';
import AuthService from '../../services/auth.service';
import { Redirect } from 'react-router-dom';
//import addEllepssisToString from '../../utils/misc.utils';
import { medDateTime, dateSortA } from '../../utils/date.utils';


const CollectionCreateForm = Form.create({ name: 'form_manage_user' })(

    class extends Component {
        /**
         * Filters teams, returns all teams in edit mode or 
         * returns un-deleted teams in creation mode
         * @param {Object} selectedUser
         * @param {string} selectedUser.isDeleted
         */
        filterTeams(selectedUser) {
            return ({ isDeleted }) => (selectedUser || !isDeleted);
        }

        render() {
            const {
                visible, onCancel, onCreate, form, confirmLoading, title, okText, selectedUser, teams
            } = this.props;
            const { getFieldDecorator } = form;
            const formItemLayout = {
                labelCol: {
                    xs: { span: 4 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 4 },
                    sm: { span: 16 },
                },
            };
            return (
                <Modal
                    visible={visible}
                    title={title}
                    okText={okText}
                    onCancel={onCancel}
                    onOk={onCreate}
                    confirmLoading={confirmLoading}
                >
                    <Form >
                        <Form.Item label="Team" {...formItemLayout}>
                            {getFieldDecorator('team', {
                                rules: [{ required: true, message: 'Please select user team' }],
                                initialValue: selectedUser ? selectedUser.team : null
                            })(<Select>
                                {teams.filter(this.filterTeams(selectedUser)).map(({ id, teamName, isDeleted }) => <Select.Option value={id} key={id}>{teamName} {isDeleted ? '(Deleted)' : ''}</Select.Option>)}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label="First Name" {...formItemLayout}>
                            {getFieldDecorator('firstName', {
                                rules: [{ required: true, message: 'Please enter user first name' }],
                                initialValue: selectedUser ? selectedUser.firstName : null

                            })(
                                <Input allowClear />
                            )}
                        </Form.Item>
                        <Form.Item label="Last Name" {...formItemLayout}>
                            {getFieldDecorator('lastName', {
                                rules: [{ required: true, message: 'Please enter user last name' }],
                                initialValue: selectedUser ? selectedUser.lastName : null

                            })(
                                <Input allowClear />
                            )}
                        </Form.Item>
                        <Form.Item label="Role/Resposibility" {...formItemLayout}>
                            {getFieldDecorator('role', {
                                rules: [{ required: true, message: 'Please enter user role' }],
                                initialValue: selectedUser ? selectedUser.role : null

                            })(
                                <Input allowClear />
                            )}
                        </Form.Item>
                        <Form.Item label="Work Email" {...formItemLayout}>
                            {getFieldDecorator('emailAddress', {
                                rules: [{ required: true, type: 'email', message: 'Please enter user email' }],
                                initialValue: selectedUser ? selectedUser.emailAddress : null

                            })(
                                <Input allowClear />
                            )}
                        </Form.Item>
                        <Form.Item label="Password" {...formItemLayout}>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please enter user password' }],
                                initialValue: selectedUser ? '********' : null
                            })(
                                <Input.Password disabled={!!selectedUser} />
                            )}
                        </Form.Item>
                        <Form.Item label="Confirm Password" {...formItemLayout}>
                            {getFieldDecorator('confirmPassWord', {
                                rules: [{ required: true, message: 'Please confirm user password' }],
                                initialValue: selectedUser ? '********' : null
                            })(
                                <Input.Password disabled={!!selectedUser} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);
class Users extends Component {

    userService = UsersService.create();
    teamService = TeamService.create();
    authService = AuthService.create();

    constructor(props) {
        super(props)
        this.state = {
            users: [],
            teams: [],
            showEditForm: false,
            confirmLoading: false,
            okText: '',
            formTitle: '',
            selectedUser: null,
            isLoggedIn: true,
            loading: true
        }
    }
    componentDidMount() {
        if (!this.authService.isUserSignedIn()) {
            message.warn("User session is invalid on has expired. Please sign in.");
            this.setState({ isLoggedIn: false });
            return;
        }
        this.teamService.fetchTeams().then((snapshot) => {
            const teams = this.state.teams;
            snapshot.forEach(doc => {
                teams.push({ id: doc.id, ...doc.data() });
            })
            this.setState({ teams }, this.isDone);
        }).catch(() => {
            this.isDone();
            message.error("Something went wrong getting the teams.");
        });

        this.userService.fetchUsers().then((snapshot) => {
            const users = this.state.users;
            snapshot.forEach(doc => {
                users.push({ id: doc.id, ...doc.data() });
            })
            this.setState({ users }, this.isDone);
        }).catch(() => {
            this.isDone();
            message.error("Something went wrong getting the users.");
        });
    }

    isDone() {
        this.state.loading && this.state.users && this.state.teams && this.setState({ loading: false });
    }

    onNewUser = (user) => {
        this.state.users.push(user);
        this.setState({ users: this.state.users });
    }
    onResetPwd(email) {
        this.authService.resetPwd(email)
            .then(() => {
                message.success("Reset email successfully sent to" + email);
            })
            .catch((error) => {
                message.error(error.message);
            });
    }
    onDelete(id) {
        let deletedUser = this.state.users.find(item => item.id === id);

        // remove the id
        deletedUser.isDeleted = true;
        delete deletedUser.id;
        this.userService.deleteUser(id, deletedUser).then(() => {
            const _users = Object.assign(this.state.users, this.state.users
                .map(item => item.id === id ? { id: item.id, ...deletedUser } : { ...item }));
            this.setState({ users: _users, selectedUser: null });
            message.success("User removed successfully.");
        }).catch((error) => {
            console.log(error)
            this.setState({ selectedUser: null });
            message.error("Something went wrong deleting the user");
        });
    }

    onEditModal = (id) => {
        const selectedUser = this.state.users.find(item => item.id === id);
        this.setState({ showEditForm: true, okText: "Update", formTitle: "Edit user", selectedUser: selectedUser });
    }
    onAddModal = () => {
        this.setState({ showEditForm: true, okText: "Add", formTitle: "Add a new user" });
    }

    handleCancel = () => {
        this.formRef.props.form.resetFields();
        this.setState({ showEditForm: false, selectedUser: null });
    }

    handleCreate = () => {
        this.setState({ confirmLoading: true });
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                this.setState({ confirmLoading: false });
                return;
            }
            const email = values.emailAddress;
            const password = values.password;
            let user = {
                firstName: values.firstName,
                lastName: values.lastName,
                role: values.role,
                team: values.team,
                emailAddress: email,
                isDeleted: false
            };
            if (this.state.selectedUser) {
                user = {
                    ...this.state.selectedUser,
                    ...user,
                    modifiedBy: sessionStorage.getItem('currentUser'),
                    dateModified: new Date().toISOString()
                }
                this.userService.updateUser(this.state.selectedUser.id, user)
                    .then(() => {
                        const _users = Object.assign(this.state.users, this.state.users
                            .map(item => item.id === this.state.selectedUser.id ? { id: item.id, ...user } : { ...item }));
                        this.setState({ users: _users, confirmLoading: false, showEditForm: false, selectedUser: null });
                        message.success("User updated successfully.");
                        form.resetFields();
                    })
                    .catch((error) => {
                        console.log(error)
                        this.setState({ confirmLoading: false, showEditForm: false, selectedUser: null });
                        message.error("Something went wrong updating the user.");
                        form.resetFields();
                    });
            }
            else {
                user = {
                    ...user,
                    createdBy: sessionStorage.getItem('currentUser'),
                    entryDate: new Date().toISOString(),
                }
                this.userService.createUser(email, password)
                    .then(() => {
                        // createUserWithEmailAndPassword signs in the created user.
                        user.uid = firebase.auth().currentUser.uid;
                        return this.userService.insertUser(user)
                    })
                    .then((docRef) => {
                        message.success("New user added successfully.");
                        form.resetFields();
                        this.onNewUser({ id: docRef.id, ...user });
                        this.setState({ confirmLoading: false, showEditForm: false });
                    }).catch((error) => {
                        console.log(error)
                        this.setState({ confirmLoading: false, showEditForm: false });
                        message.error(error.message);
                        form.resetFields();
                    });
            }


        });
    }

    getTeamNameById = (team) => {
        const _team = this.state.teams.find(({ id }) => team === id) || {};
        return _team.teamName || '';
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    render() {
        if (!this.state.isLoggedIn) {
            return <Redirect to={ROUTES.HOME} />
        }
        const { Content } = Layout;
        const data = this.state.users.filter(user => user.isDeleted === false);
        const columns = [
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                sorter: (a, b) => a.description.localeCompare(b.firstName),
                render: (text) => text,
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                sorter: (a, b) => a.description.localeCompare(b.lastName),
                render: (text) => text,
            },
            {
                title: 'Team',
                dataIndex: 'team',
                key: 'team',
                sorter: (a, b) => this.getTeamNameById(a.team).localeCompare(this.getTeamNameById(b.team)),
                render: (text) => this.getTeamNameById(text),
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                sorter: (a, b) => a.role.localeCompare(b.role),
                render: (text) => text,
            },
            {
                title: 'Date',
                dataIndex: 'entryDate',
                key: 'entryDate',
                sorter: (a, b) => (dateSortA(a.entryDate, b.entryDate)),
                defaultSortOrder: 'descend',
                render: (text) => medDateTime(text),
            },
            {
                title: 'Actions',
                key: 'action',
                align: 'right',
                render: (text, record) => (
                    <span>
                        <Button type="primary" icon="reload" onClick={() => this.onResetPwd(record.emailAddress)}>Reset Password</Button>
                        <Divider type="vertical" />
                        <Button type="primary" icon="edit" onClick={() => this.onEditModal(record.id)}></Button>
                        <Divider type="vertical" />
                        <Popconfirm placement="right" title="Are you sure to delete this user?" onConfirm={() => this.onDelete(record.id)} okText="Yes" cancelText="No">
                            <Button type="danger" icon="delete"></Button>
                        </Popconfirm>

                    </span>
                ),
            }
        ];

        return (

            <Layout className='layout'>
                <ContentHeader />
                <Content className='content'>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Users</Breadcrumb.Item>
                        <Breadcrumb.Item>Manage</Breadcrumb.Item>
                    </Breadcrumb>
                    <Layout className='inner-content'>
                        <Sidebar defaultOpenKeys={[ROUTES.USERS]} selectedKeys={["manage-users"]} onAddModal={this.onAddModal} />
                        <Content style={{ padding: '0 24px', minHeight: 280 }}>
                            <Table columns={columns} dataSource={data} rowKey={record => record.id} size="small" loading={this.state.loading} />
                            <CollectionCreateForm
                                wrappedComponentRef={this.saveFormRef}
                                visible={this.state.showEditForm}
                                onCancel={this.handleCancel}
                                onCreate={this.handleCreate}
                                confirmLoading={this.state.confirmLoading}
                                okText={this.state.okText}
                                title={this.state.formTitle}
                                selectedUser={this.state.selectedUser}
                                teams={this.state.teams}
                            />
                        </Content>
                    </Layout>
                </Content>
                <ContentFooter>
                </ContentFooter>
            </Layout>
        );
    }
}

export default Users;
