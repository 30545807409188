/**
 * Sidebar.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @author Jonas Tomanga <celleb@logicpp.com.na>
 * @copyright (c) 2019 Logic Plus Information Technologies CC
 * All rights reserved
 * 
 */

import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import * as ROUTES from '../../constants/Routes';
import { NavLink } from 'react-router-dom';


class Sidebar extends Component {

    render() {
        const {
            Sider
        } = Layout;

        return (
            <Sider width={200} style={{ background: '#fff' }}>
                <Menu
                    mode="inline"
                    defaultOpenKeys={this.props.defaultOpenKeys}
                    style={{ height: '100%' }}
                    selectedKeys={this.props.selectedKeys}
                >
                    <Menu.SubMenu key={ROUTES.PUBLICATIONS} title={<span><Icon type="file-pdf" /><span>Publications</span></span>}>
                        <Menu.Item key="manage-publications" ><Icon type="edit" />
                            <NavLink to={ROUTES.PUBLICATIONS} className="menu-text">Manage</NavLink>
                        </Menu.Item>
                        <Menu.Item onClick={() => { this.props.onAddModal() }} disabled={!this.props.defaultOpenKeys.includes(ROUTES.PUBLICATIONS)}><Icon type="plus" />
                            Add new
                        </Menu.Item>
                    </Menu.SubMenu >

                    <Menu.SubMenu key={ROUTES.EVENTS} title={<span><Icon type="calendar" /><span>News &amp; Events</span></span>}>
                        <Menu.Item key="manage-events" ><Icon type="edit" />
                            <NavLink to={ROUTES.EVENTS} className="menu-text">Manage</NavLink>
                        </Menu.Item>
                        <Menu.Item onClick={() => { this.props.onAddModal() }} disabled={!this.props.defaultOpenKeys.includes(ROUTES.EVENTS)}><Icon type="plus" />
                            Add new
                        </Menu.Item>
                    </Menu.SubMenu >

                    <Menu.SubMenu key={ROUTES.PROJECTS} title={<span><Icon type="folder-open" /><span>Projects</span></span>}>
                        <Menu.Item key="manage-projects" ><Icon type="edit" />
                            <NavLink to={ROUTES.PROJECTS} className="menu-text">Manage</NavLink>
                        </Menu.Item>
                        <Menu.Item onClick={() => { this.props.onAddModal() }} key="add-projects" disabled={!this.props.defaultOpenKeys.includes(ROUTES.PROJECTS)}><Icon type="plus" />
                            Add new
                        </Menu.Item>
                    </Menu.SubMenu >
                    <Menu.SubMenu key={ROUTES.USERS} title={<span><Icon type="user" /><span>Users</span></span>}>
                        <Menu.Item key="manage-users" ><Icon type="edit" />
                            <NavLink to={ROUTES.USERS} className="menu-text">Manage</NavLink>
                        </Menu.Item>
                        <Menu.Item onClick={() => { this.props.onAddModal() }} disabled={!this.props.defaultOpenKeys.includes(ROUTES.USERS)}><Icon type="plus" />
                            Add new
                        </Menu.Item>
                    </Menu.SubMenu >

                    <Menu.SubMenu key={ROUTES.TEAMS} title={<span><Icon type="team" /><span>Teams</span></span>}>
                        <Menu.Item key="manage-teams"><Icon type="edit" />
                            <NavLink to={ROUTES.TEAMS} className="menu-text">Manage</NavLink>
                        </Menu.Item>
                        <Menu.Item onClick={() => { this.props.onAddModal() }} key="add-themes" disabled={!this.props.defaultOpenKeys.includes(ROUTES.TEAMS)}><Icon type="plus" />
                            Add new
                        </Menu.Item>
                    </Menu.SubMenu >

                    <Menu.SubMenu key={ROUTES.THEMES} title={<span><Icon type="project" /><span>Themes</span></span>}>
                        <Menu.Item key="manage-themes" ><Icon type="edit" />
                            <NavLink to={ROUTES.THEMES} className="menu-text">Manage</NavLink>
                        </Menu.Item>
                        <Menu.Item onClick={() => { this.props.onAddModal() }} key="add-teams" disabled={!this.props.defaultOpenKeys.includes(ROUTES.THEMES)}><Icon type="plus" />
                            Add new
                        </Menu.Item>
                    </Menu.SubMenu >
                </Menu>
            </Sider>
        );
    }
}

export default Sidebar;


