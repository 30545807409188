/**
 * ResetForm.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import {
    Form, Icon, Input, Button, message
} from 'antd';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/Routes';
import AuthService from '../../services/auth.service'

class Reset extends Component {
    authService = AuthService.create();

    state = {
        loading: false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;        
            }
            this.enterLoading();
            this.authService.resetPwd(values.userEmail)
            .then(()=>{
                message.success("Email sent successfully.");
                this.exitLoading();
            })
            .catch((error)=>{
                message.error(error.message);
                this.exitLoading();
            })
        });
    }

    enterLoading = () => {
        this.setState({ loading: true });
    }

    exitLoading = () => {
        this.setState({ loading: false });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="reset-form">
                <Form.Item>
                    {getFieldDecorator('userEmail', {
                        rules: [{ required: true, message: 'Please enter your email!', type:"email" }],
                    })(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                        Reset Password</Button>
                    <Link className="reset-form-forgot" to={ROUTES.HOME}  >Have an existing account? Login here</Link>
                </Form.Item>
            </Form>
        );
    }
}

const ResetForm = Form.create({ name: 'normal_login' })(Reset);

export default ResetForm;