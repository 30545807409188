
/**
 * LoginForm.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import React, { Component } from 'react';
import {
    Form, Icon, Input, Button, message,
} from 'antd';
import { Link, Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/Routes';
import AuthService from '../../services/auth.service'
import firebase from 'firebase/app';

class Login extends Component {

    authService = AuthService.create();

    state = {
        loading: false,
        isLoggedIn: false,
        user: null
    }

    enterLoading = () => {
        this.setState({ loading: true });
    }

    exitLoading = () => {
        this.setState({ loading: false });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.enterLoading();
            this.authService.setPersistence()
                .then(() => {
                    return this.authService.authenticateUser(values.email, values.password)
                })
                .then(() => {
                    sessionStorage.setItem('currentUser', firebase.auth().currentUser.uid);
                    this.exitLoading();
                    this.setState({ isLoggedIn: true, user: firebase.auth().currentUser });
                })
                .catch((error) => {
                    this.exitLoading();
                    message.error(error.message);
                })

        });
    }

    render() {
        if (this.state.isLoggedIn) {
            return <Redirect to={ROUTES.PUBLICATIONS} />
        }
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className="login-form">

                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please enter a valid email.', type: "email" }],
                    })(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Please enter your password.' }],
                    })(
                        <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" />
                    )}
                </Form.Item>
                <Form.Item>
                    <Link className="login-form-forgot" to={ROUTES.RESET_PWD}>Forgot password</Link>
                    <Button type="primary" htmlType="submit" className="login-form-button" icon="login" loading={this.state.loading} >
                        Log in</Button>
                </Form.Item>
            </Form>

        );
    }
}

const LoginForm = Form.create({ name: 'normal_login' })(Login);

export default LoginForm;