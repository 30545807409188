/**
 * misc.utils.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

export default function addEllepssisToString(value) {
    if (value.length > 25)
        return value.substring(0, 22) + "...";
    return value;
}