/**
 * publications.service.js
 *
 * @author Gervasius Ishuuwa <gervasius@logicpp.com.na>
 * @copyright (c) 2018 Logic Plus Information Technologies CC
 * All rights reserved
 */

import firebase from 'firebase/app';
import 'firebase/firestore';
//import 'firebase/firebase-storage';

let instance;

export default class PublicationsService {

     db = firebase.firestore();
    storageRef = firebase.storage().ref();
    collection = "publications";

    fetchPublications = () => {
        return this.db.collection(this.collection).get();
    }

    insertPublication = (publication) => {
        return this.db.collection(this.collection).add({
            ...publication
        });
    }

    updatePublication  = (id, publication) => {
        return this.db.collection(this.collection).doc(id).set({
            ...publication
        });
    }

    deletePublication = (id, publication) => {
        return this.db.collection(this.collection).doc(id).set({
            ...publication
        });
    }

    uploadFile = (file) => {
        return this.storageRef.child(this.collection + '/' + file.uid).put(file);
    }

    static create() {
        if (!instance) {
            instance = new PublicationsService();
        }
        return instance;
    }

}